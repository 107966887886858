import * as React from "react"
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Section from "../components/section"
import prod from '../images/prod2.jpg';
import rec from '../images/rec3.jpg';
import studio from '../images/studio.jpg';
import collab from '../images/collab.jpg';

const IndexPage = ({ data }) => {
  const image = getImage(data.coverImage)
  return (
    <Layout pageTitle="Home Page" hero={{
      title: "ATELIER.SOUND",
      image,
      alt: "",
      extend: true,
    }}>
      <div className="flex flex-col md:flex-row md:flex-wrap lg:flex-row">
        <Section name="PROD."
          route="/prod"
          image={prod}
          extraClasses="bg-center"/>
        <Section name="REC."
          route="/rec"
          image={rec} />
        <Section name="STUDIO."
          route="/studio"
          image={studio}
          extraClasses="bg-center bg-[length:500px]"/>
        <Section name="COLLAB."
          route="/collab"
          image={collab}
          extraClasses="bg-[center_top_-1.25rem] bg-[length:432px] lg:bg-[length:482px]"/>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    coverImage: file(name: {eq:"cover2"}) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`