import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby';

const Section = ({ name, image, route, extraClasses }) => {
  const styles = {
    backgroundImage: `url(${image})`,
    backgroundColor: 'rgba(0,0,0,.40)'
  }

  return (
    <Link to={route} className="md:w-1/2 xl:flex-1">
      <div className={`flex items-end justify-between p-8 h-56 lg:h-64 bg-blend-darken bg-cover ${extraClasses}`} style={styles}>
        <h2 className="text-3xl bg-black text-white px-2 font-medium">{name}</h2>
        <span className="bg-black p-2 flex place-center">
          <FontAwesomeIcon className="text-white" icon={faArrowRight}></FontAwesomeIcon>
        </span>
      </div>
    </Link>
  )
}

export default Section